import clsx from 'clsx'
import { useEffect, useMemo } from 'react'
import {
  aHoverFeature,
  aMapCurrentCount,
  aSelectedListing,
  useAtomValue,
  useSetAtom,
} from '~/atoms'
import { Button } from '~/components/ui'
import { type Listing } from '~/models'
import { cn, debounce } from '~/utils'
import MapListingCard from './Card'
import MapListingCardSkeleton from './CardSkeleton'

export default function MapCardList({
  className,
  listings,
  isLoading,
  isFetching,
  fetchNextPage,
  hasNextPage,
}: {
  className?: string
  listings: Listing[]
  reportSelectedListing?: Listing | null
  isLoading?: boolean
  isFetching?: boolean
  fetchNextPage?: () => void
  hasNextPage?: boolean
}) {
  const currentMapCount = useAtomValue(aMapCurrentCount)
  const setHoverFeature = useSetAtom(aHoverFeature)
  const selectedListing = useAtomValue(aSelectedListing)

  useEffect(() => {
    const elem = document.getElementById('cards-list')
    if (!fetchNextPage) {
      return
    }
    const onScroll = () => {
      if (
        hasNextPage &&
        !isFetching &&
        elem!.scrollTop > elem!.scrollHeight - elem!.scrollHeight / 4
      ) {
        fetchNextPage()
      }
    }
    const debouncedOnScroll = debounce(onScroll, 100)
    elem!.addEventListener('scroll', debouncedOnScroll)
    return () => elem!.removeEventListener('scroll', debouncedOnScroll)
  }, [hasNextPage, isFetching, fetchNextPage])

  const loadingCount = useMemo(() => {
    if (currentMapCount === 0 || (selectedListing && currentMapCount === 1)) {
      return 0
    }
    return 8
  }, [currentMapCount, selectedListing])

  return (
    <div className={cn(className, 'hidden md:flex')}>
      <div
        id="cards-list"
        className={cn(
          'z-50 grid place-content-start gap-4 overflow-auto pb-4',
          '2xl:grid-cols-2 ',
          'md:grid-cols-1',
          'ml-4'
        )}>
        {isLoading && listings.length === 0
          ? Array.from({ length: loadingCount }, (_, index) => index).map(
              (index) => {
                return (
                  <MapListingCardSkeleton
                    key={index}
                    className={clsx(
                      'md:w-[350px] 2xl:w-[350px]',
                      loadingCount < 4 && 'col-span-2'
                    )}
                  />
                )
              }
            )
          : listings.map((listing) => {
              return (
                <MapListingCard
                  key={listing._id}
                  className={clsx(
                    isFetching && 'animate-pulse',
                    'md:w-[350px] 2xl:w-[350px]',
                    loadingCount < 4 && 'col-span-2'
                  )}
                  listing={listing}
                  onMouseOver={() => {
                    if (!selectedListing)
                      setHoverFeature({
                        address: listing.property.address,
                        lat: listing.property.latitude,
                        lng: listing.property.longitude,
                        name: listing.name,
                        propertyTypes: listing.propertyTypes.join(', '),
                        status: listing.status,
                        type: listing.type,
                        _id: listing._id,
                      })
                  }}
                  onMouseOut={() => {
                    setHoverFeature(null)
                  }}
                />
              )
            })}
        {hasNextPage && fetchNextPage && (
          <div className="col-span-full flex justify-center">
            <Button disabled={isFetching} onClick={() => fetchNextPage()}>
              {isFetching ? 'Loading' : 'Load More'}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
