import { aUser, useAtomValue } from '~/atoms'
import { Separator } from '~/components/ui'
import { PROPERTY_TYPES_COLORS, cn } from '~/utils'

export default function MapLegend() {
  const user = useAtomValue(aUser)

  return (
    <div
      id="map-legend"
      className={cn(
        'bg-background absolute right-4 space-y-1 rounded-md px-2 py-1.5 text-xs shadow-md',
        user ? 'bottom-20' : 'bottom-6'
      )}>
      <div className="flex items-center gap-2">
        <span className="legend-triangle" />
        <span className="text-xs">Sale</span>
      </div>
      <div className="flex items-center gap-2">
        <span className="h-3 w-3 rounded-full bg-[#c026d3]"></span>
        <span className="text-xs">Lease</span>
      </div>
      <div className="flex items-center gap-2">
        <span className="h-3 w-3 bg-[#4f46e5]"></span> Sale/Lease
      </div>
      <Separator className="!my-2" />
      {PROPERTY_TYPES_COLORS.map(({ propertyType, className }, index) => {
        return (
          <div key={index} className="flex items-center gap-2">
            <span className={cn('h-3 w-3 rounded-full', className)}></span>
            <span className="text-xs">{propertyType}</span>
          </div>
        )
      })}
    </div>
  )
}
