import { useNavigation } from '@remix-run/react'
import area from '@turf/area'
import { convertArea } from '@turf/helpers'
import { useMemo } from 'react'
import { aDrawnFeature, useAtomValue } from '~/atoms'
import { LoadingCircle } from '~/components'

export default function ListingCountLoading({
  isLoading,
  count,
  totalCount,
}: {
  isLoading: boolean
  count: number | null
  totalCount?: number
}) {
  const navigation = useNavigation()
  const drawnFeature = useAtomValue(aDrawnFeature)

  const drawnArea = useMemo(() => {
    if (!drawnFeature) {
      return null
    }
    const squareMeters = area(drawnFeature)
    return convertArea(squareMeters, 'meters', 'miles')
  }, [drawnFeature])

  const content = useMemo(() => {
    if (isLoading || (!count && count !== 0)) {
      return (
        <span className="flex">
          <LoadingCircle className="text-primary h-6 w-6" />
          <span className="ml-2">Loading</span>
        </span>
      )
    } else if (count > 0) {
      if (totalCount) {
        return (
          <>
            {navigation.state === 'loading' && (
              <LoadingCircle className="text-primary mr-2 h-6 w-6" />
            )}
            <span>
              {`${count.toLocaleString()} / ${totalCount.toLocaleString()}`}{' '}
              Listings
            </span>
          </>
        )
      }
      return <span>{count.toLocaleString()} Listings</span>
    } else if (totalCount && totalCount > 0) {
      return <span>0 / {totalCount.toLocaleString()} Listings</span>
    }
    return <span>No Listings Found</span>
  }, [isLoading, count, navigation, totalCount])

  return (
    <div className="bg-background absolute left-1/2 top-4 flex -translate-x-1/2 rounded-md px-4 py-2 text-base font-medium shadow-md">
      {content}
      {drawnArea && (
        <span className="ml-2">
          ({drawnArea.toFixed(2)} mi<sup>2</sup>)
        </span>
      )}
    </div>
  )
}
